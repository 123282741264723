@font-face {
  font-family: 'blast';
  src: url('../assets/fonts/Command Blast.otf')
}
#SectionTitle{
  width: 100%;
  margin-bottom: 60px;
  text-align: left;
  font-family: blast, serif;
  @media screen and (min-width: 1250px){
    font-size: 100px;
  }
  @media screen and (min-width: 1025px) and (max-width: 1249px) {
    font-size: 70px;
  }
  @media screen and (min-width: 781px) and (max-width: 1024px) {
    font-size: 60px;
  }
  @media screen and (max-width: 780px) {
    font-size: 56px;
  }
}