#Footer {
  position: relative;
  width: 100%;
  background-color: #ea2559;
  @media screen and (max-width: 780px) {
    margin-top: 100px;
  }
  .footer-img {
    position: absolute;
    left: 0;
    right: 0;
    top: -30%;
    width: 100%;
    @media screen and (min-width: 1250px){
      top: -30%;
    }
    @media screen and (min-width: 1025px) and (max-width: 1249px) {
      top: -30%;
    }
    @media screen and (min-width: 781px) and (max-width: 1024px) {
      top: -30%;
    }
    @media screen and (max-width: 780px) {
      top: -5%;
    }
  }

  .footer-container {
    display: flex;
    margin: 50px 100px;
    justify-content: space-between;
    @media screen and (min-width: 1250px){
      margin-top: 220px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1249px) {
      margin-top: 160px;
    }
    @media screen and (min-width: 781px) and (max-width: 1024px) {
      margin-top: 80px;
    }
    @media screen and (max-width: 780px) {
      flex-direction: column;
      margin:120px 0 0 0;
    }

    .footer-box-img {
      object-fit: contain;
      @media screen and (min-width: 1250px){
        width: 660px;
      }
      @media screen and (min-width: 1025px) and (max-width: 1249px) {
        width: 480px;
      }
      @media screen and (min-width: 781px) and (max-width: 1024px) {
        width: 400px;
      }
      @media screen and (max-width: 780px) {
        width: 100%;
        object-fit: contain;
      }
    }

    .footer-content-wrapper {
      height: fit-content;

      .text-img-wrapper {
        margin-left: 14px;
        margin-right: auto;
        width: 125px;
        .logo-img {

          width: 100%;
          object-fit: contain;

        }
      }

      .footer-button-wrapper {
        margin: 108px 0 0 36px;
        @media screen and (max-width: 780px) {
          margin:40px 0 0 0;
          padding: 40px;
        }
        .footer-button {
          padding-bottom: 10px;
          margin-bottom: 20px;
          border-bottom: 1px solid #fbfbfb;
          cursor: pointer;
          @media screen and (min-width: 1250px){
            width: 342px;
          }
          @media screen and (min-width: 1025px) and (max-width: 1249px) {
            width: 280px;
          }
          @media screen and (min-width: 781px) and (max-width: 1024px) {
            width: 120px;
          }
          @media screen and (max-width: 780px) {
          }
          .footer-button-text {
            text-align: left;
            font-family: blast, serif;
            color: #fbfbfb;
            @media screen and (min-width: 1250px){
              font-size: 20px;
            }
            @media screen and (min-width: 1025px) and (max-width: 1249px) {
              font-size: 18px;
            }
            @media screen and (min-width: 781px) and (max-width: 1024px) {
              font-size: 16px;
            }
            @media screen and (max-width: 780px) {
            }
          }
        }

        .footer-button-red {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 342px;
          height: 68px;
          background-color: #ea2559;
          color: #fbfbfb;
          font-size: 20px;
          font-weight: bold;
          @media screen and (min-width: 1250px){
            width: 342px;
            font-size: 20px;
          }
          @media screen and (min-width: 1025px) and (max-width: 1249px) {
            width: 280px;
            font-size: 16px;
          }
          @media screen and (min-width: 781px) and (max-width: 1024px) {
            width: 160px;
            font-size: 14px;
          }
          @media screen and (max-width: 780px) {
          }
        }
      }
    }
  }
}
