@font-face {
  font-family: "blast";
  src: url("../assets/fonts/Command Blast.otf");
}

#Header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 40px 0;

  .logo-wrapper {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 36px;
    .logo-img {
      width: 125px;
      object-fit: contain;
    }
  }
  .header-content-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    @media screen and (max-width: 780px) {
      flex-direction: column;
    }
    .text-img-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-right: 40px;
      @media screen and (max-width: 780px) {
        margin-right: 0;
      }
      .header-img {
        width: 700px;
        object-fit: contain;
        @media screen and (min-width: 1250px){
          width: 700px;
        }
        @media screen and (min-width: 1025px) and (max-width: 1249px) {
          width: 560px;
        }
        @media screen and (min-width: 781px) and (max-width: 1024px) {
          width: 400px;
        }
        @media screen and (max-width: 780px) {
          width: 100%;
        }
      }
    }
    .header-button-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-top: 0;
      @media screen and (max-width: 780px) {
        margin-top: 28px;
        width: 100%;
      }
      .header-button {
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid black;
        @media screen and (min-width: 1250px){
          width: 342px;
        }
        @media screen and (min-width: 1025px) and (max-width: 1249px) {
          width: 280px;
        }
        @media screen and (min-width: 781px) and (max-width: 1024px) {
          width: 160px;
        }
        @media screen and (max-width: 780px) {
          width: 100%;
        }
        .header-button-text {
          text-align: left;
          font-family: blast, serif;
          cursor: pointer;

          @media screen and (min-width: 1250px){
            font-size: 40px;
          }
          @media screen and (min-width: 1025px) and (max-width: 1249px) {
            font-size: 28px;
          }
          @media screen and (min-width: 781px) and (max-width: 1024px) {
            font-size: 20px;
          }
          @media screen and (max-width: 780px) {
            font-size: 40px;
          }
        }
      }
      .header-button-red {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 68px;
        background-color: #ea2559;
        color: #fbfbfb;
        font-weight: bold;
        @media screen and (min-width: 1250px){
          width: 342px;
          font-size: 20px;
        }
        @media screen and (min-width: 1025px) and (max-width: 1249px) {
          width: 280px;
          font-size: 16px;
        }
        @media screen and (min-width: 781px) and (max-width: 1024px) {
          width: 160px;
          font-size: 14px;
        }
        @media screen and (max-width: 780px) {
        }
      }
    }
  }
}
