#Section2{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .content-wrapper{
    position: relative;
    width: 100%;
    @media screen and (min-width: 1350px){
      height: 1300px;
    }
    @media screen and (min-width: 1250px) and (max-width: 1349px) {
      height: 800px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1249px) {
      height: 900px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1199px) {
      height: 640px;
    }
    @media screen and (min-width: 781px) and (max-width: 1024px) {
      height: 640px;
    }
    @media screen and (min-width: 680px) and (max-width: 780px) {
      height: 1100px;
    }
    @media screen and (min-width: 580px) and (max-width: 681px) {
      height: 950px;
    }
    @media screen and (min-width: 480px) and (max-width: 579px) {
      height: 750px;
    }
    @media screen and (min-width: 380px) and (max-width: 479px) {
      height: 600px;
    }
    @media screen and (max-width: 379px) {
      height: 400px;
    }
    .section-img{
      position: absolute;
      z-index: 99;
      left:0;
      right:0;
      width: 100%;
      object-fit: contain;

      @media screen and (min-width: 1250px){
        top: -640px;
      }
      @media screen and (min-width: 1025px) and (max-width: 1249px) {
        top: -520px;
      }
      @media screen and (min-width: 781px) and (max-width: 1024px) {
        top: -400px;
      }
      @media screen and (max-width: 780px) {
        top: -220px;
      }
    }
  }
  .section-description{
    margin: 110px 0;
    @media screen and (max-width: 780px) {
      margin-top: 50px;
    }
    text-align: left;
    .description-row{
      margin-bottom: 40px;
    }
  }
}