#Section3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 110px;

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media screen and (max-width: 780px) {
      flex-direction: column;
    }
    .description-wrapper {
      width: 50%;
      text-align: left;
      @media screen and (min-width: 1250px){
        font-size: 20px;
      }
      @media screen and (min-width: 1025px) and (max-width: 1249px) {
        font-size: 18px;
      }
      @media screen and (min-width: 781px) and (max-width: 1024px) {
        font-size: 16px;
      }
      @media screen and (max-width: 780px) {
        width: 100%;
      }
      .description-row {
        margin-bottom: 10px;

        &.margin-bottom {
          margin-bottom: 40px;
        }

        &.margin-top {
          margin-top: 40px;
        }
      }
    }
    .market-img-wrapper{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .market-img {
      width: 50%;
      object-fit: contain;
      @media screen and (max-width: 780px) {
        width: 100%;
        margin-top: 40px;
      }
    }
  }
  .album-wrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 100px;
    @media screen and (max-width: 780px) {
      flex-direction: column;
    }
    .album-img{
      object-fit: contain;
      @media screen and (min-width: 1250px){
        width: 260px;
      }
      @media screen and (min-width: 1025px) and (max-width: 1249px) {
        width: 200px;
      }
      @media screen and (min-width: 781px) and (max-width: 1024px) {
        width: 140px;
      }
      @media screen and (max-width: 780px) {
        width: 100%;
      }
    }
  }
}