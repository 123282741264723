#Section1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 110px 0;

  .tech-item-wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
    @media screen and (max-width: 780px) {
      gap: 0;
    }
  }
}

.tech-item {
  display: flex;
  flex-direction: column;
  max-width: 250px;
  margin-bottom: 0;
  @media screen and (max-width: 780px) {
    max-width: 100%;
    margin-bottom: 60px;
  }
  .main-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    border-left: 3px solid black;
    text-align: left;
    @media screen and (min-width: 1250px){
      height: 145px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1249px) {
      height: 125px;
    }
    @media screen and (min-width: 781px) and (max-width: 1024px) {
      height: 115px;
    }
    @media screen and (max-width: 780px) {
      height: 140px;
    }
    .main-text {
      font-weight: bold;
      @media screen and (min-width: 1250px){
        font-size: 32px;
      }
      @media screen and (min-width: 1025px) and (max-width: 1249px) {
        font-size: 28px;
      }
      @media screen and (min-width: 781px) and (max-width: 1024px) {
        font-size: 24px;
      }
      @media screen and (max-width: 780px) {
        font-size: 32px;
      }
    }

    .sub-text {
      @media screen and (min-width: 1250px){
        font-size: 20px;
      }
      @media screen and (min-width: 1025px) and (max-width: 1249px) {
        font-size: 18px;
      }
      @media screen and (min-width: 781px) and (max-width: 1024px) {
        font-size: 16px;
      }
      @media screen and (max-width: 780px) {
        font-size: 20px;
      }
    }
  }

  .description-wrapper {
    margin-top: 25px;
    padding-left: 33px;
    text-align: left;
    line-height: 28px;
    @media screen and (min-width: 1250px){
      font-size: 20px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1249px) {
      font-size: 18px;
    }
    @media screen and (min-width: 781px) and (max-width: 1024px) {
      font-size: 16px;
    }
    @media screen and (max-width: 780px) {
      font-size: 20px;
    }
  }

}