@font-face {
  font-family: "CommandBlast";
  src: url("../assets/fonts/Command\ Blast.otf") format("truetype");
}

#Section4 {
  width: 100%;
  padding: 0 0 110px;
  box-sizing: border-box;
  text-align: start;

  h1 {
    font-family: "CommandBlast";
    font-size: 100px;
    margin: 0 0 100px 9px;
  }

  .image-box {
    overflow: hidden;
    width: 100%;

    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
