@font-face {
  font-family: "Inter-Light";
  src: url("../assets/fonts/Inter-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Bold";
  src: url("../assets/fonts/Inter-Bold.ttf") format("truetype");
}

#Section5 {
  width: 100%;
  box-sizing: border-box;
  text-align: start;

  @media screen and (min-width: 1250px){
    margin-bottom: 500px;
  }
  @media screen and (min-width: 1025px) and (max-width: 1249px) {
    margin-bottom: 400px;
  }
  @media screen and (min-width: 781px) and (max-width: 1024px) {
    margin-bottom: 300px;
  }
  @media screen and (max-width: 780px) {
  }
  .intro {
    margin-bottom: 56px;
  }

  p {
    font-family: "Inter-Light";
    margin: 24px 0;
    line-height: 24px;
    @media screen and (min-width: 1250px){
      font-size: 20px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1249px) {
      font-size: 18px;
    }
    @media screen and (min-width: 781px) and (max-width: 1024px) {
      font-size: 16px;
    }
    @media screen and (max-width: 780px) {
    }
  }

  .bold {
    font-family: "Inter-Bold";
  }
}
