#Main {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .content-wrapper {
    width: calc(100% - 200px);
    padding: 0 100px;
    @media screen and (min-width: 1250px){
      max-width: 1236px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1249px) {
    }
    @media screen and (min-width: 781px) and (max-width: 1024px) {
    }
    @media screen and (max-width: 780px) {
      width: calc(100% - 32px);
      padding: 0 16px;
      gap: 0;
    }
    .content-img{
      width: 100%;
      object-fit: contain;
      margin-top: -4px;
    }
  }

}
.img-modal{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding:40px 0;
  background-color: #00000066;

  .img-modal-body-container {
    position: relative;
    overflow-y: auto;
    .close-btn{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      object-fit: contain;
      position: absolute;
      right: 30px;
      top: 24px;
      cursor: pointer;
      @media screen and (max-width: 780px) {
        width: 30px;
        right: 24px;
        top: 16px;

      }
    }
    .modal-img{
      width: 100%;
      object-fit: contain;
    }
  }
}
